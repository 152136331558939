import React, {useRef} from "react";
import { Viewer, Cesium3DTileset, Model, CameraFlyTo, ImageryLayer } from "resium";
import { Cartesian3, createWorldTerrain, HeadingPitchRoll, Matrix4, Transforms, IonImageryProvider, WebMapServiceImageryProvider, UrlTemplateImageryProvider, CesiumTerrainProvider  } from "cesium";

const terrainProvider = createWorldTerrain();

// const terrainProvider = new CesiumTerrainProvider({
//     url: "/data/terrain/terrain",
//     // requestVertexNormals : true
// })


// f they are in Earth Fixed, the code would just be simple edition:

//Convert origin to earth-fixed Cartesian
const oLat = 48.596469219722214;
const oLon =  18.8557145835899;
const oAlt = 340;
const offset = 200;
const heading = 900;
// const heading = 0;
const pitch = 0;
const roll = 0;


const App = () => {
    
    const ref = useRef(null);

    const getPosition = () => {
        var position = Cartesian3.fromDegrees(oLon, oLat, oAlt);
        debugger
        // Then for each offset you just add the values

        // var finalPos = Cartesian3.add(position, offset, new Cartesian3());

        // If the code is in another reference frame, it needs to be transformed, but you need to know what frame that is. For example, if it’s eastNorthUP

        //Convert origin to earth-fixed

        var position = Cartesian3.fromDegrees(oLon, oLat, oAlt);

        //Compute a transform that converts ENU offsets to Earth-fixed

        var transform = Transforms.eastNorthUpToFixedFrame(position);
        var transform = Transforms.headingPitchRollToFixedFrame(position, new HeadingPitchRoll(heading, pitch, roll));
        // Then for each offset you just apply the transform.

        // var finalPos = Matrix4.multiplyByPointAsVector(transform, offset, new Cartesian3());


        // var ENU = new Matrix4();
        // var ellipsoid = viewer.scene.globe.ellipsoid;
        // var position = Cesium.Cartesian3.fromDegrees(0,0,0);
        // var ellipsoid =ref.current?.cesiumElement?.scene.globe.ellipsoid
        // Transforms.eastNorthUpToFixedFrame(position,ellipsoid,ENU);
        console.log(transform);
        return transform;

    }
    return (
        <Viewer 
            navigationInstructionsInitiallyVisible={false} 
            terrainProvider={terrainProvider} 
            fullscreenButton={false}
            navigationHelpButton={false}
            creditContainer={document.createElement("div")}
            animation={false}
            infoBox={false}
            // creditViewport=""
            timeline={false}
            full 
            ref={ref}
        >
                <CameraFlyTo
                    duration={1}
                    // pitchAdjustHeight={}
                    // orientation={}
                    // maximumHeight={}
                    
                    // destination={Cartesian3.fromDegrees(19.349241, 48.447520, 10000)}
                    destination={Cartesian3.fromDegrees(18.8615145835899, 48.589769219722214, 10000)}
                />
             {/* <ImageryLayer alpha={0.5} imageryProvider={new IonImageryProvider({ assetId: 3812 })} /> */}
             {/* <ImageryLayer
                imageryProvider={
                    new WebMapServiceImageryProvider({
                        url : "https://zbgisws.skgeodesy.sk/zbgis_ortofoto_wms/service.svc/get",
                        layers : "0",
                        parameters: {"transparent": true, format: "image/jpeg"}
                    })
                }
                /> */}
             <ImageryLayer
                imageryProvider={
                    new UrlTemplateImageryProvider({
                        url: "/data/amas_ortofoto_EPSG3857/{z}/{x}/{y}.mixed",
                    })
                }
                />
             <ImageryLayer
                imageryProvider={
                    new WebMapServiceImageryProvider({
                        url : "https://kataster.skgeodesy.sk/eskn/services/NR/kn_wms_orto/MapServer/WmsServer",
                        layers : "0,1,2,3,4,5",
                        parameters: {"transparent": true, format: "image/png"}
                    })
                }
                />
            <ImageryLayer
                imageryProvider={
                    new WebMapServiceImageryProvider({
                        url : "https://kataster.skgeodesy.sk/eskn/services/NR/uo_wms_orto/MapServer/WmsServer",
                        layers : "0,1,2",
                        parameters: {"transparent": true, format: "image/png"}
                    })
                }
                />
            <Model
                url={"/data/ziar2/model.glb"}
                modelMatrix={getPosition()}
                minimumPixelSize={128}
                scale={0.01}
                // maximumScale={20000}
                // onReady={() => console.log("onReady")}
                onClick={() => console.log("onClick")}
                />
            {/* <Cesium3DTileset
                url="/data/hrad/pernek/tileset.json"
                onAllTilesLoad={() => console.log("onAllTilesLoad")}
                onInitialTilesLoad={() => console.log("onInitialTilesLoad")}
                onTileFailed={() => console.log("onTileFailed")}
                onTileLoad={() => console.log("onTileLoad")}
                onTileUnload={() => console.log("onTileUnload")}
                onReady={tileset => {
                    ref.current?.cesiumElement?.zoomTo(tileset);
                }}
                onClick={() => console.log("onClick")}
            /> */}
        </Viewer>   
    )
}

export default App;
